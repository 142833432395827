@font-face { font-family: Clash; src: url('./assets/fonts/clash.ttf'); }

body {
  overflow: hidden; 
  width: 1920px; height: 1080px;
}

.App {
  text-align: center;
  background-image: url("./assets/images/clashbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 1920px; height: 1080px;
}

.headerContainer {
  top: 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonContainer {
  display: flex;
}

.image-wrapper {
  position: relative;
  margin: 0 10px;
}

.label {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.hover-image:hover + .label {
  opacity: 1;
}

.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.centerContent {
  
}

.zigzag-container {
  position: fixed;
  right: -15%;
  bottom: 10%;
}

.battleButton:hover + .cryingHehe {
  transform: translateX(5%);
  opacity: 1;
  left: 650px;
}

.partyButton:hover + .partyHehe {
  transform: translateX(5%);
  opacity: 1;
}

.heheBossMan {
  position:absolute;
  top: 240px;
  left: 90px;
  width: 37px;
  height: 28px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  z-index: 1;
}

.heheBossMan:hover {
  transform: scale(1.2)
}

.firstLootContainer .firstLootGems {
  position: absolute;
  top: 640px;
  left: 250px;
  width: 100px;
  height: 100px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
}

.firstLootContainer .firstLootGems2 {
  position: absolute;
  top: 640px;
  left: 250px;
  width: 100px;
  height: 100px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
}

.firstLootContainer:hover .firstLootGems {
  opacity: 0;
  cursor: pointer;
}

.firstLootContainer:hover .firstLootGems2 {
  opacity: 1;
  cursor: pointer;
}
.firstLootContainer .firstLootGems:hover {
  cursor: pointer;
}


.firstLootContainer p {
  position: absolute;
  top: 2px;
  left: 1480px;
  transform: translateX(-50%);
  font-size: 30px; /* Adjust font size as needed */
  color: #ffffff; /* White text color */
  text-shadow: 0 0 3px #000000; /* Black shadow */
  font-family: 'Clash', sans-serif; /* Use custom font */
  -webkit-text-stroke: 2px #000000; /* Black stroke */
  border-radius: 8px;
  z-index: 1;
}


.cryingHehe,
.partyHehe {
  width: 50px;
  height: 50px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  position: absolute;
}

.battleButton,
.partyButton {
  transition: transform 0.3s ease-in-out;
  position: relative;
  z-index: 1;
}

.image-wrapper2 {
  position: relative;
  margin-bottom: 10px;
}

.zigzag-image {
  width: 110px;
  height: auto;
  transition: transform 0.3s ease-in-out;
}
.test-slide-image {
  width: 400px;
  height: auto;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.test-slide-image2 {
  width: 400px;
  height: auto;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.image-wrapper2:nth-child(even) .zigzag-image {
  margin-right: -300px;
}

.image-wrapper2:nth-child(odd) .zigzag-image {
  margin-right: 300px;
}

.test-slide-image {
  transform: translateX(-25%);
  opacity: 0;
}

.image-wrapper2:hover .test-slide-image {
  transform: translateX(-212px);
  opacity: 1;
}

.test-slide-image2 {
  transform: translateX(25%);
  opacity: 0; 
}

.image-wrapper2:hover .test-slide-image2 {
  transform: translateX(-300px);
  opacity: 1; 
}

.image-wrapper2:hover .zigzag-image {
  transform: scale(1.4);
}



.leftHeader {
  
}

.centerHeader {
  display: flex;
  justify-content: center;
  text-align: center;
}

.rightHeader {
  padding-right: 20px;
}

.footer {
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
}

.footerButton {
  transition: transform 0.3s ease-in-out;
}

.footerButton:hover {
  transform: scale(0.8);
}

section .set {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

@keyframes animate {
  0% {
      opacity: 0;
      top: -10%;
      transform: translateX(20px) rotate(0deg);
  }

  10% {
      opacity: 1;
  }

  20% {
      transform: translateX(-20px) rotate(45deg);
  }

  40% {
      transform: translateX(-20px) rotate(90deg);
  }

  60% {
      transform: translateX(20px) rotate(180deg);
  }

  80% {
      transform: translateX(-20px) rotate(180deg);
  }

  100% {
      top: 110%;
      transform: translateX(-20px) rotate(225deg);
  }
}

section .set div {
  position: absolute;
  display: block;
  overflow: clip;
}

section .set div:nth-child(1) {
  left: 20%;
  animation: animate 15s linear infinite;
  animation-delay: -7s;
}

section .set div:nth-child(2) {
  left: 50%;
  animation: animate 20s linear infinite;
  animation-delay: -5s;
}

section .set div:nth-child(3) {
  left: 70%;
  animation: animate 20s linear infinite;
  animation-delay: 0s;
}

section .set div:nth-child(4) {
  left: 0%;
  animation: animate 15s linear infinite;
  animation-delay: -5s;
}

section .set div:nth-child(5) {
  left: 85%;
  animation: animate 18s linear infinite;
  animation-delay: -10s;
}

section .set div:nth-child(6) {
  left: 20%;
  animation: animate 15s linear infinite;
  animation-delay: -7s;
}

section .set div:nth-child(7) {
  left: 0%;
  animation: animate 12s linear infinite;

}

section .set div:nth-child(8) {
  left: 60%;
  animation: animate 15s linear infinite;
}

.set2 {
  transform: scale(2) rotateY(180deg);
  filter: blur(2px);
}

.set3 {
  transform: scale(0.8) rotateX(180deg);
  filter: blur(4px);
}

.set img {
  width: 40%;
}

.popup {
  position: fixed;
  top: 0; 
  left: 0;
  transform: translate(0, 630%);
  z-index: 999;
  max-width: 30%;
}

.popup img {
  max-width: 400px;
  height: auto;
}

.popup .close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 25px;
  color: #333;
}