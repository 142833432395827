.intro-video-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 1920px; height: 1080px;
    overflow: hidden;
    z-index: 1;
}

.intro-video-container video {
    width: 100%;
    height: 100%;
}
