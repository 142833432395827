.heheha-page-container {
    position: relative;
    width: 1920px;
    height: 1080px;
    background-image: url("./assets/final_page_images/final_bgd.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

/* Scrollable content inside the parent container */
.scrollable-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding: 20px;
}

.grid-image {
    width: 100%;
    height: auto;
    border: 2px solid #FFD700;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
}

.grid-image:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.scrolling-text-bar {
    width: 100%;
    background-color: #FFD700;
    color: #0033cc;
    overflow: hidden;
    white-space: nowrap;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    padding: 10px 0;
}

.scrolling-text {
    display: inline-block;
    padding-left: 100%;
    animation: scroll-text 15s linear infinite;
    font-size: 20px;
}

@keyframes scroll-text {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.divFooter {
    padding-bottom: 100px;
}