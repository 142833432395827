@font-face { font-family: Clash; src: url('./assets/fonts/clash.ttf'); }

.popup-containerParty {
    position: absolute;
    margin-right: 400px;
    background-image: url("./assets/images/popUpBorderTest.gif");
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-family: Clash, sans-serif;
    z-index: 999;
    max-width: 662px;
    height: 457px;
    width: 100%;
  }

  .popup-video {
    margin-top: 15px;
    margin-left: 0px;
    max-height: 370px;
    width: 636px;
  }
  
  .popup-headerParty {
    display: flex;
    justify-content: flex-end;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  .popup-content {
    margin-top: 15px;
    margin-left: 20px;
    max-height: 370px;
    width: 636px;
    overflow-y: auto;
  }

  .popup-text {
    padding: 5px;
    font-family: Clash, sans-serif;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-size: 16px;
    line-height: 1.5;
    white-space: pre-wrap; /* Preserve line breaks */
  }

  .close-button img {
    width: 50px; /* set the width of the close button image */
    height: 50px; /* set the height of the close button image */
    margin-right: 3px;
    margin-top: 3px;
  }

  .popup-image {
    width: 100%; /* Make the image take up 100% of the container width */
    height: auto; /* Maintain the aspect ratio of the image */
  }

  