/* NewPage.css */

.new-page-container {
    text-align: center;
    background-image: url("./assets/images/testBgd.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 1920px; height: 1080px;
}
.New-Page-Main {
    top: 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.questionOne {
    position:absolute;
    cursor: pointer;
    top: 650px;
    left: 320px;
    z-index: 1;
}
.questionTwo {
    position:absolute;
    cursor: pointer;
    top: 170px;
    left: 320px;
    z-index: 1;
}
.questionThree {
    position:absolute;
    cursor: pointer;
    top: 170px;
    left: 910px;
    z-index: 1;
}
.questionFour {
    position:absolute;
    cursor: pointer;
    top: 170px;
    left: 1500px;
    z-index: 1;
}
.questionFive {
    position:absolute;
    cursor: pointer;
    top: 650px;
    left: 1490px;
    z-index: 1;
}
.error-message {
    color: red;
    margin-top: 10px;
}

.hehe2nd {
    position:absolute;
    cursor: pointer;
    top: 400px;
    left: 1750px;
    z-index: 1;
}